/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import writeXlsxFile from 'write-excel-file';
import { cloneDeep, isEmpty } from 'lodash';
import PIUploadStatus from './PIUploadStatus';
import { SimDateTime } from '../../utils/datetime';
import { mockedFileData, mockedSessionData, mockedVarianceData } from '../../components/tutorial/mockTutorialData/physicalInventoryUpload';
import { newRelicAction } from '../../utils/newRelicPageActions';

import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

export const formatBytes = (b) => {
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = b;

  while (n >= 1024) {
    n /= 1024;
    l += 1;
  }

  return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`;
};

export const jsonTryParse = (value, defaultValue) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch (error) {
      return defaultValue;
    }
  }
  return defaultValue;
};

export const isDuplicate = (lastSession, currentSession) => {
  const firstSession = JSON.stringify({ gtins: lastSession?.gtins, locations: lastSession?.locations, quantity: lastSession?.quantity });
  const secondSession = JSON.stringify({ gtins: currentSession?.gtins, locations: currentSession?.locations, quantity: currentSession?.quantity });
  return firstSession === secondSession;
};

export const setPIUploadTutorialSteps = (
  isbackClicked, restartTutorial, startTutorial, stepIndex, tutorialVarianceData, setBackWasClicked, setTutorialAlertMessage,
  setTutorialButtonsDisabled, setTutorialLastDeltaSessionId, setTutorialSessionData, setTutorialVarianceData, setTimeOutOnTutorial,
) => {
  const sessionStorageKey = 'piUploadFilesTutorial';
  if (startTutorial) {
    if (stepIndex === 0) {
      const file = mockedFileData;
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(file));
    }
    if (stepIndex === 1) {
      setTutorialSessionData(mockedSessionData);
      setTutorialButtonsDisabled(false);
      setTutorialAlertMessage(PIUploadStatus.FILE_UPLOAD_COMPLETED);
    }
    if (stepIndex === 5 && isbackClicked) {
      setTutorialLastDeltaSessionId(null);
      setTutorialAlertMessage(PIUploadStatus.FILE_UPLOAD_COMPLETED);
      setTutorialButtonsDisabled(false);
    }
    if (stepIndex === 6 && isbackClicked && tutorialVarianceData) {
      setTutorialVarianceData(null);
      setBackWasClicked(false);
      setTimeOutOnTutorial(500, 6);
    }
    if (stepIndex === 6 && !isbackClicked && !tutorialVarianceData) {
      setTutorialLastDeltaSessionId('1');
      setTutorialAlertMessage(PIUploadStatus.STOCK_UPDATED);
      setTutorialButtonsDisabled(true);
    }
    if (stepIndex === 7) {
      setTutorialVarianceData(mockedVarianceData);
    }
  }
  if (restartTutorial) {
    sessionStorage.removeItem(sessionStorageKey);
    setTutorialSessionData(null);
    setTutorialLastDeltaSessionId(null);
    setTutorialAlertMessage(null);
    setTutorialButtonsDisabled(true);
    setTutorialVarianceData(null);
  }
};

/**
 * A helper function to filter the variance data
 * @param {array} data the variance table data
 * @param {array} filterBy an array with list of filter values
 */
export const filterVarianceData = (data, filterBy) => {
  if (!data || isEmpty(data)) return [];
  if (!filterBy) return data;

  const columnList = ['productCode', 'division', 'description', 'gtin', 'size', 'oldStock', 'newStock', 'variance'];
  const copyOfData = cloneDeep(data);
  const filterObject = [];

  columnList.map((item, index) => {
    const [filterValue] = filterBy[index];
    if (filterValue) {
      filterObject.push({ name: item, value: filterValue });
    }
  });

  if (!isEmpty(filterObject)) return copyOfData.filter(item => filterObject.some(filter => filter.value === item[filter.name]));
  return copyOfData;
};

/**
 * A helper function to paginate the variance data apply with sort, filter and search
 * @param {array} data the variance table data
 * @param {number} page the pagination page number
 * @param {number} rowsPerPage the number of rows to show per page
 * @param {function} setTotalCounts a function to set total item count
 * @param {object} sortBy an object with sort direction and column name
 * @param {string} searchText the search string
 * @param {array} filterBy an array with list of filter values
 * @param {boolean} returnAll an indicator to skip pagination, uses for download
 */
export const getPaginatedVarianceData = (data, page, rowsPerPage, setTotalCounts, sortBy, searchText, filterBy, returnAll = true) => {
  if (!data || isEmpty(data)) return null;

  let copyOfData = cloneDeep(data);
  if (sortBy) {
    // eslint-disable-next-line no-nested-ternary
    copyOfData.sort((a, b) => (sortBy.direction === 'desc' ? (a[sortBy.name] < b[sortBy.name] ? 1 : -1) : (b[sortBy.name] > a[sortBy.name] ? -1 : 1)));
  }
  if (searchText) {
    copyOfData = copyOfData.filter(item => {
      const result = JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
      return result;
    });
  }
  if (filterBy) copyOfData = filterVarianceData(copyOfData, filterBy);
  if (returnAll) return copyOfData;
  if (setTotalCounts) setTotalCounts(copyOfData.length);

  const dataInChunk = Array.from({ length: Math.ceil(copyOfData.length / rowsPerPage) }, (v, i) => copyOfData.slice(i * rowsPerPage, i * rowsPerPage + rowsPerPage));

  return dataInChunk[page] || [];
};

export const buildDownloadData = (allData, sortBy, searchText, filterBy, csvFormat) => {
  const toDownload = [];
  const newData = getPaginatedVarianceData(allData, null, null, () => {}, sortBy, searchText, filterBy, true);
  newData?.map(r => toDownload.push({
    'Style-Color': r?.productCode || '-',
    Division: r?.division || '-',
    Description: r?.description || '-',
    UPC: `${csvFormat}${r?.gtin}` || '-',
    Size: r?.size || '-',
    SOH: r?.oldStock || 0,
    'PI SOH': r?.newStock || 0,
    Variance: r?.variance || 0,
  }));
  return toDownload;
};

export const toExcel = (storeNumber, allData, sortBy, searchText, filterBy, csvFormat) => {
  const dataRows = [];
  const dateTime = SimDateTime.toDateTime().toLocaleString(DATE_TIME_SHORT_WITH_APPENDED_ZEROS);
  const fileName = `PI-VarianceReport.${storeNumber}.${dateTime}`;

  try {
    if (allData) {
      const csvData = buildDownloadData(allData, sortBy, searchText, filterBy, csvFormat ?? '');
      dataRows.push(Object.keys(csvData?.[0]).map(value => ({ value: value.toString(), type: String })));
      csvData.map(row => dataRows.push(Object.values(row).map((value, index) => {
        if (index >= 5) {
          return { value: parseInt(value, 10) ?? 0, type: Number };
        }
        return { value: value?.toString() ?? '', type: String };
      })));
    }
    writeXlsxFile(dataRows, { fileName: `${fileName}.xlsx` });
    newRelicAction(fileName, { isDownloaded: true });
  } catch (error) {
    newRelicAction(fileName, error, { isDownloaded: false });
  }
};
